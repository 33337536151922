import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/aggregates/lab/form-schema';
import { AggregatesLabTestsRepository } from 'modules/aggregates/lab/services/repository';
import { AggControlType }               from 'modules/aggregates/models/control-type';
import { CommentFormSchema }            from 'modules/core/custom-components/comments/form-schema';
import { Sector }                       from 'modules/management/models/sector';
import { ListingsRepository }           from 'modules/management/specifications/listings/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, AggregatesLabTestsRepository, AggControlType, FormSchema, CommentFormSchema, ListingsRepository)
export class CreateAggregatesLabTest extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    routePrefix = 'aggregates.lab';
    permissionPrefix;

    alert            = {};
    model            = {};
    activeControlTab = false;
    tabs             = {};

    create             = true;
    showAdditionalInfo = true;
    showCommentsTab    = true;

    constructor(appContainer, repository, controlType, formSchema, commentSchema, listingsRepository) {
        super(appContainer);

        this.listingsRepository = listingsRepository;
        this.repository         = repository;
        this.controlType        = controlType;
        this.formSchema         = formSchema;
        this.commentSchema      = commentSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/aggregates/lab/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {
        this.category = params.category;
        this.repository.setCategory(this.category);

        this.formId           = `aggregates-${this.category}-lab-creation-form`;
        this.permissionPrefix = `aggregates.${this.category}.lab`;
        this.routePrefix      = `aggregates.${this.category}.lab`;
        this.sectorId         = (this.category === 'not-treated')
            ? Sector.AGGREGATES_NT_LAB
            : Sector.AGGREGATES_T_LAB;

        this.backRoute       = `${this.routePrefix}.index`;
        this.backRouteParams = params;
        this.showCommentsTab = true;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.create`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.create',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initializeModel();

        // Fetch the next number and selected lot information
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Assign base lot information
        this.assignBaseLotInformation(selectedLotInformation);

        // Get schema for each tab
        this.tabs.selectBulletins          = await this.formSchema.selectBulletinsSchema.schema(this);
        this.tabs.general                  = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema    = this.formSchema.lotInfoSchema(this);
        this.tabs.workControl              = await this.formSchema.workControlSchema.schema(this);
        this.tabs.materialCharacterization = await this.formSchema.materialCharacterizationSchema.schema(this);
        this.tabs.additionalInfo           = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema                  = this.formSchema.globalSchema(this);
        this.commentSchema                 = this.commentSchema.schema(this);
    }

    async initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.comment = this.commentSchema.model();
        this.model.comment        = this.commentSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param selectedLotInformation
     */
    assignBaseLotInformation(selectedLotInformation) {
        const baseInfo = {
            entity_name:            selectedLotInformation.entity_name,
            entity_id:              selectedLotInformation.entity_id,
            team_name:              selectedLotInformation.team_name,
            team_id:                selectedLotInformation.team_id,
            highway_name:           selectedLotInformation.highway_name,
            highway_id:             selectedLotInformation.highway_id,
            concession_name:        selectedLotInformation.concession_name,
            concession_id:          selectedLotInformation.concession_id,
            intervention_type_name: selectedLotInformation.lot_intervention_type_name,
            intervention_type_id:   selectedLotInformation.lot_intervention_type_id,
            lot_intervention_name:  selectedLotInformation.lot_intervention_name,
            lot_intervention_id:    selectedLotInformation.lot_intervention_id,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.sectorId, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   this.backRoute,
                    params: this.backRouteParams,
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Update Control Tabs
     * @param controlTypeId
     */
    updateControlTabs(controlTypeId) {
        this.activeControlTab = controlTypeId;
    }
}
