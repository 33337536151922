import { inject }                     from 'aurelia-framework';
import { TracksRepository }           from 'modules/aggregates/in-situ/services/tracks-repository';
import { ConstructionSchema }         from 'modules/aggregates/lab/tab-schemas/construction-schema';
import { OEDatasRepository }          from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { FieldsRepository }           from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { LotConstructionType }        from 'modules/management/models/lot-construction-type';
import { Sector }                     from 'modules/management/models/sector';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, TracksRepository, FieldsRepository, LotInterventionsRepository, OEDatasRepository)
export class WorkControlSchema extends ConstructionSchema {

    schemaSlug = 'work_control';

    /**
     *
     * @param appContainer
     * @param tracksRepository
     * @param fieldsRepository
     * @param lotInterventionsRepository
     * @param oeDatasRepository
     */
    constructor(appContainer, tracksRepository, fieldsRepository, lotInterventionsRepository, oeDatasRepository) {
        super(lotInterventionsRepository, oeDatasRepository, tracksRepository)
        this.appContainer = appContainer;
        this.fieldsRepository = fieldsRepository;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        // Initialize Construction fields
        await super.schema(viewModel, readonly);

        // Edit PK form group label
        this.applied_pk.label = 'form.field.test-pk';

        const IN_SITU_WORK_CONTROL = Sector.IN_SITU_WORK_CONTROL;

        const fieldsObserver = (source, target, criteria) => {
            if (!source.input.instance) return;

            let newValue = source.input.instance.model.value;

            // convert target to an array if it's not already
            const targets = Array.isArray(target) ? target : [target];

            targets.forEach((t) => {

                if (t.input.instance) {
                    t.input.instance.model.value = null;

                    if (newValue) {
                        t.input.attributes.disabled = false;

                        t.input.remoteSource = () => getFields(t.name, source.name, newValue, criteria);

                        t.input.instance.fetchData();
                    } else {
                        t.input.attributes.disabled = true;
                    }
                }

            });
        };

        const getFields = (field, source, value, criteria) => {
            return this.fieldsRepository.getFields(
                viewModel.sectorId,
                field,
                {
                    [source]: value,
                    ...criteria,
                },
            );
        };

        this.performed_test_id = {
            id:           'test-field-select',
            type:         'select2',
            key:          'performed_test_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.performed-test',
            remoteSource: () => this.fieldsRepository.getFields(viewModel.sectorId,
                'performed-test',
                { slug: [IN_SITU_WORK_CONTROL] },
            ),
            observers:    [
                () => fieldsObserver(
                    {
                        input: this.performed_test_id,
                        name:  'performed_test_id',
                    },
                    {
                        input: this.part_zone_application_id,
                        name:  'part-zone-application',
                    },
                    { slug: [IN_SITU_WORK_CONTROL] }),
            ],
            required: false,
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.part_zone_application_id = {
            type:         'select2',
            key:          'part_zone_application_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.part-zone-application',
            remoteSource: () => (viewModel.model.performed_test_id)
                ? getFields(
                    'part-zone-application',
                    'performed_test_id',
                    viewModel.model.performed_test_id,
                    { slug: [IN_SITU_WORK_CONTROL] })
                : Promise.resolve([]),
            observers:    [
                () => fieldsObserver(
                    {
                        input: this.part_zone_application_id,
                        name:  'part_zone_application_id',
                    },
                    {
                        input: this.layer_id,
                        name:  'layer',
                    },
                    { performed_test_id: this.performed_test_id.instance.model.value }),
            ],
            required: false,
            size:         6,
            attributes:   {
                disabled: viewModel.model.performed_test_id === null,
            },
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.layer',
            remoteSource: () => (viewModel.model.part_zone_application_id)
                ? getFields(
                    'layer',
                    'part_zone_application_id',
                    viewModel.model.part_zone_application_id,
                    { performed_test_id: viewModel.model.performed_test_id })
                : Promise.resolve([]),
            required: false,
            size:         6,
            attributes:   {
                disabled: viewModel.model.part_zone_application_id === null,
            },
        };

        this.reference_sample_id = {
            type:         'select2',
            key:          'reference_sample_id',
            label:        'form.field.reference-aggregate-sample-to-treat-num',
            remoteSource: () => viewModel.labRepository.referenceSamples(),
            required: false,
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.depth_quota = {
            type:       'number',
            key:        'depth_quota',
            label:      'form.field.depth-quota(m)',
            required: false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.axis_distance = {
            type:       'number',
            key:        'axis_distance',
            label:      'form.field.axis-distance(m)',
            required: false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.layer_thickness = {
            type:       'number',
            key:        'layer_thickness',
            label:      'form.field.layer-thickness(m)',
            required: false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.complementary_tests_ids = {
            type:         'duallistbox',
            key:          'complementary_tests_ids',
            label:        'form.field.complementary-tests',
            remoteSource: () => viewModel.repository.complementary(viewModel.model.id),
            required: false,
            size:         12,
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.performed_test_id, this.part_zone_application_id],
            [this.layer_id, this.reference_sample_id],
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.collection_pk, this.applied_pk],
            [this.number_of_tracks, this.axis],
            [this.track_id, this.depth_quota],
            [this.axis_distance, this.layer_thickness],
            [this.complementary_tests_ids],
        ];

    }
}
